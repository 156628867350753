<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        title="绑定电杆资产"
        custom-class="common-edit"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="200" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="是否绑定生产厂家序列号：" prop="is_production">
                <el-radio-group v-model="dataForm.is_production">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="是否绑定资产管理序列号：" prop="is_assets">
                <el-radio-group v-model="dataForm.is_assets">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { nextTick, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { BIND_WIREPOLE } from "@/api/asset";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    ids: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

// 表单数据
let dataForm = ref({
    is_production: 0,
    is_assets: 0
});

let dataFormRef = ref();

let dataRules = {
    is_production: [{ required: true, message: "请选择是否绑定生产厂家序列号！", trigger: "change" }],
    is_assets: [{ required: true, message: "请选择是否绑定资产管理序列号！", trigger: "change" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            BIND_WIREPOLE({
                ...dataForm.value,
                ids: props.ids
            }).then((res) => {
                if (res.data.code === 200) {
                    onClose();
                    ElMessage.success("绑定成功");
                }
            });
        }
    });
};
</script>
