import { ref } from "vue";

export default function () {
    // 数据列表
    let dataPagination = ref({
        current: 1,
        size: 10,
        total: 0
    });

    let dataList = ref([]);

    // 选中
    let selectionIds = ref([]);

    let changeSelectionIds = (val) => {
        selectionIds.value = val.map((item) => {
            return item.id;
        });
    };

    return {
        dataPagination,
        dataList,
        selectionIds,
        changeSelectionIds
    };
}
