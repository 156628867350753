<template>
    <!--<gk-searchbar-->
    <!--    v-model:form="searchForm"-->
    <!--    :config="searchConfig"-->
    <!--    :foldable="true"-->
    <!--    :fold-display-number="3"-->
    <!--    :options="searchOptions"-->
    <!--    @search="getDataList"-->
    <!--    @reset="getDataList"-->
    <!--/>-->
    <div class="common-list">
        <div class="button-wapper">
            <el-button type="primary" @click="openEditDialog()">新增</el-button>
            <el-button @click="openBindDialog()">批量绑定</el-button>
            <!--<el-button type="danger" @click="onDelete()">删除</el-button>-->
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" />
                <el-table-column label="设备" prop="equipment" />
                <el-table-column label="生产厂家序列号">
                    <template #default="scope">
                        {{ scope.row.production !== 0 ? scope.row.production_serial.name : "未绑定" }}
                    </template>
                </el-table-column>
                <el-table-column label="资产管理序列号">
                    <template #default="scope">
                        {{ scope.row.assets !== 0 ? scope.row.assets_serial.name : "未绑定" }}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="created_at" />
                <el-table-column label="操作" width="150">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" disabled @click="openEditDialog(scope.row)">
                            编辑
                        </el-button>
                        <el-button plain size="mini" type="danger" @click="onDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <wire-pole-edit
        v-model:show="editDialog.show"
        :id="editDialog.id"
        :detail="editDialog.detail"
        @update="getDataList"
    />
    <wire-pole-bind v-model:show="bindDialog.show" :ids="selectionIds" @update="getDataList" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import useCommonList from "../../../../hooks/useCommonList";
import WirePoleEdit from "./AssetWirePoleEdit.vue";
import WirePoleBind from "./AssetWirePoleBind.vue";
import { DELETE_WIREPOLE, GET_WIREPOLE_LIST } from "@/api/asset";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "name", type: "input", label: "规则名称" }]);

let searchOptions = ref({});

let searchForm = ref({
    name: null
});

// 获取数据
let getDataList = () => {
    GET_WIREPOLE_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 增删改
let editDialog = ref({
    show: false,
    id: null,
    detail: null
});

let openEditDialog = (item = null) => {
    editDialog.value = {
        show: true,
        id: item ? item.id : null,
        detail: item
    };
};

let onDelete = (id = null) => {
    // if (id) selectionIds.value = [id];
    ElMessageBox.confirm("确认删除该电杆资产？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
    }).then(() => {
        DELETE_WIREPOLE({ id: id }).then((res) => {
            if (res.data.code === 200) {
                getDataList();
                ElMessage.success("删除成功");
            }
        });
    });
};

// 绑定
let bindDialog = ref({
    show: false
});

let openBindDialog = () => {
    console.log(selectionIds.value);
    if (!selectionIds.value || selectionIds.value.length <= 0) {
        ElMessage.error("请先选择需要绑定的电杆！");
        return false;
    }
    bindDialog.value.show = true;
};
</script>
