import { httpDelete, httpGet, httpPost, httpPut } from "@/api/request";
import $store from "@/store";

// 电杆资产管理
export function GET_WIREPOLE_LIST(params) {
    return httpGet("/asset/pole/list\n", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function ADD_WIREPOLE(params) {
    return httpPost("/asset/pole/add", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
export function DELETE_WIREPOLE(params) {
    return httpDelete("/asset/pole", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function BIND_WIREPOLE(params) {
    return httpPut("/asset/pole/batch", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
